import React, { useEffect } from 'react'

const Calendly = ({}) => {
  useEffect(() => {
    const head: any = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head.appendChild(script)
  }, [])

  return (
    <div
      className="calendly-inline-widget h-full w-full"
      data-url="https://calendly.com/the-sheet-society/shop-in-store"
    />
  )
}
export default Calendly
