import { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

interface props {
  mediaDesktop: {
    url: string
  }
  mediaMobile?: {
    url: string
  }
  // Sets which screen size to show mobile/desktop media
  breakPoint?: number
  settings?: {
    [key: string]: boolean | string
  }
  style?: string
}

const DynanmicVideo: FC<props> = ({ mediaDesktop, mediaMobile, breakPoint, settings, style = '' }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [mediaSource, setMediaSource] = useState({ url: '' })

  useEffect(() => {
    const resizeListener = () => {
      if (breakPoint && window.innerWidth < breakPoint && mediaMobile?.url) {
        if (mediaSource !== mediaMobile) {
          setMediaSource(mediaMobile)
          if (videoRef.current) {
            videoRef.current.load()
          }
        }
      } else {
        if (mediaSource != mediaDesktop) {
          setMediaSource(mediaDesktop)
          if (videoRef.current) {
            videoRef.current.load()
          }
        }
      }
    }

    window.addEventListener('resize', resizeListener)

    if (!videoRef.current?.src) {
      setMediaSource(breakPoint && window?.innerWidth < breakPoint && mediaMobile?.url ? mediaMobile : mediaDesktop)
      videoRef.current?.load()
      videoRef.current?.addEventListener('load', () => videoRef.current?.play())
    }

    return () => window.removeEventListener('resize', resizeListener)
  }, [mediaDesktop, mediaMobile, breakPoint, mediaSource])

  return (
    <video ref={videoRef} className={cn(`dynamic-video w-full h-full object-cover ${style}`)} {...settings}>
      <source src={mediaSource.url} type="video/mp4" />
    </video>
  )
}

export default DynanmicVideo