import { PrismicImage } from '@lib/prismic'
import Image from 'next/legacy/image'
import { FC, useEffect, useState } from 'react'

interface props {
  mediaDesktop: PrismicImage
  mediaMobile?: PrismicImage
  // Sets which screen size to show mobile/desktop media
  breakPoint?: number
  settings?: {
    loop: boolean
  }
  style?: any
  imageSettings?: {
    [key: string]: string
  }
}

const DynanmicImage: FC<props> = (props) => {
  const defaultSettings: { layout: 'fill'; objectFit: 'cover' } = {
    layout: 'fill',
    objectFit: 'cover',
  }

  const { mediaDesktop, mediaMobile, breakPoint = 768, imageSettings = defaultSettings, ...rest } = props
  const [mediaSource, setMediaSource] = useState(mediaDesktop)

  useEffect(() => {
    const resizeListener = () => {
      if (breakPoint && window.innerWidth < breakPoint && mediaMobile?.url) {
        if (mediaSource !== mediaMobile) {
          setMediaSource(mediaMobile)
        }
      } else {
        if (mediaSource != mediaDesktop) {
          setMediaSource(mediaDesktop)
        }
      }
    }

    window.addEventListener('resize', resizeListener)

    return () => window.removeEventListener('resize', resizeListener)
  }, [mediaDesktop, mediaMobile, breakPoint, mediaSource])

  useEffect(() => {
    setMediaSource(breakPoint && window?.innerWidth < breakPoint && mediaMobile?.url ? mediaMobile : mediaDesktop)
  }, [breakPoint, mediaMobile?.url, mediaDesktop, mediaMobile])

  return <Image src={mediaSource.url} alt={mediaSource.alt || 'Sheet Society'} {...imageSettings} {...rest} />
}

export default DynanmicImage
