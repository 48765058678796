import { DynamicImage, DynamicVideo } from '@components/common'
import Calendly from '@components/common/Calendly'
import EmbeddedVideo from '@components/common/EmbeddedVideo'
import { Button } from '@components/ui'
import ClickOutside from '@lib/click-outside'
import { SplitPanelV3Props } from '@lib/types/SplitPanel'
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { RichText } from 'prismic-reactjs'
import { FC, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

interface props {
  primary: SplitPanelV3Props
}

const SplitPanelV3: FC<props> = ({ primary }) => {
  const defaultAlt = 'The Sheet Society'

  const {
    embedded_video,
    background_colour,
    panel_image,
    panel_image_mobile,
    image_title,
    image_position,
    panel_text_style,
    panel_title,
    panel_title_size,
    panel_title_padding_bottom,
    panel_subtitle,
    panel_contents,
    button_texts,
    button_link,
    padding_top,
    padding_bottom,
    mobile_padding_top,
    mobile_padding_bottom,
    button_style,
    styling_appointment,
    square_media_ratio,
    video,
    gif,
  } = primary || {}

  const panelTitlePaddingBottom = panel_title_padding_bottom && Number(panel_title_padding_bottom)

  const [openCalendly, setOpenCalendly] = useState(false)
  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const handleOpenCalendly = () => {
    const sidebar = divRef.current
    disableBodyScroll(sidebar, { reserveScrollBarGap: true })
    setOpenCalendly(true)
  }
  const handleCloseCalendly = () => {
    const sidebar = divRef.current
    enableBodyScroll(sidebar)
    clearAllBodyScrollLocks()
    setOpenCalendly(false)
  }

  const { ref, inView } = useInView({
    threshold: 0,
  })
  const isPanelTextLight = panel_text_style === 'light'
  const bg = isPanelTextLight ? 'dark' : 'light'
  return (
    <section className="split-panel-v3" style={{ background: background_colour }} ref={ref}>
      <div
        className={cn('wrapper splitPanelV3Container grid grid-cols-8 gap-x-12 laptop:grid-cols-12 laptop:gap-x-16')}
        ref={divRef}
      >
        <div
          className={cn(
            'split-panel-v3__content col-span-8 laptop:col-start-2 laptop:col-span-4 flex items-center w-full'
          )}
        >
          <div className="mb-40 laptop:mb-0">
            {panel_subtitle && (
              <h2 className={`title ${panel_text_style === 'light' ? 'text-white' : 'text-moss'} pb-8 uppercase`}>
                {panel_subtitle}
              </h2>
            )}

            {panel_title && (
              <h2
                className={cn(
                  `pb-16  ${panel_title_size} ${
                    panel_text_style === 'light' ? 'text-white' : ''
                  } laptop:pb-${panelTitlePaddingBottom}`,
                  {
                    'heading-5': !panel_title_size,
                    'laptop:pb-28': !panelTitlePaddingBottom,
                  }
                )}
              >
                {panel_title}
              </h2>
            )}
            {panel_contents && (
              <div className={`body ${panel_text_style === 'light' ? 'text-white' : ''}`}>
                <RichText render={panel_contents} />
              </div>
            )}
            {button_texts && !styling_appointment && (
              <div className="mt-28 laptop:mt-40">
                <Link href={button_link || '/'} passHref legacyBehavior>
                  <Button variant={button_style || 'primary'} bg={bg} colorSchema={panel_text_style}>
                    {button_texts}
                  </Button>
                </Link>
              </div>
            )}

            {styling_appointment && button_texts && (
              <div className="mt-28 laptop:mt-40">
                <Button
                  variant={button_style || 'primary'}
                  bg={bg}
                  colorSchema={panel_text_style}
                  onClick={handleOpenCalendly}
                >
                  {button_texts}
                </Button>
              </div>
            )}

            {openCalendly && (
              <>
                <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 duration-100 ease-linear inset-0 z-50 flex justify-center"></div>

                <ClickOutside active={openCalendly} onClick={handleCloseCalendly}>
                  <div className="calendlyContainer">
                    <Calendly />
                  </div>
                </ClickOutside>
              </>
            )}
          </div>
        </div>
        {panel_image?.url && !gif.url && !video.url && !embedded_video?.html && (
          <div className={`split-panel-v3__image relative col-span-8 laptop:col-start-7 laptop:col-span-6`}>
            <DynamicImage mediaDesktop={panel_image} mediaMobile={panel_image_mobile} breakPoint={1024} />
            {image_title && (
              <h2 className="font-b-regular text-60 leading-68 absolute imageTitle top-1/2 left-1/2 text-white">
                {image_title}
              </h2>
            )}
          </div>
        )}

        {gif.url && !video.url && !embedded_video.html && (
          <div className="split-panel-v3__video relative col-span-8 laptop:col-start-7 laptop:col-span-6">
            {inView && (
              <div className="absolute top-0 z-10 w-full h-full">
                <Image
                  src={decodeURI(gif.url)}
                  alt={gif.alt || defaultAlt}
                  layout="fill"
                  objectFit="cover"
                  sizes={'(min-width: 1024px) calc((1024px - 64px) / 2), 50vw'}
                />
              </div>
            )}
            {panel_image?.url && (
              <DynamicImage mediaDesktop={panel_image} mediaMobile={panel_image_mobile} breakPoint={1024} />
            )}
          </div>
        )}

        {video.url && (
          <div className="split-panel-v3__video relative col-span-8 laptop:col-start-7 laptop:col-span-6">
            {inView && (
              <DynamicVideo
                mediaDesktop={video}
                settings={{ playsInline: true, autoPlay: true, loop: true, muted: true }}
                style="absolute top-0 z-10"
              />
            )}
            {panel_image?.url && (
              <DynamicImage mediaDesktop={panel_image} mediaMobile={panel_image_mobile} breakPoint={1024} />
            )}
          </div>
        )}

        {embedded_video?.html && !video.url && (
          <div className="col-span-8 laptop:col-start-7 laptop:col-span-6">
            <EmbeddedVideo html={embedded_video?.html} width={embedded_video?.width} height={embedded_video?.height} />
          </div>
        )}
        <style jsx>
          {`
            .imageTitle {
              transform: translate(-50%, -50%);
            }

            .splitPanelV3Container {
              padding-top: ${padding_top};
              padding-bottom: ${padding_bottom};
              direction: ${image_position === 'left' ? 'rtl' : 'ltr'};
            }

            .split-panel-v3__content {
              direction: ltr;
            }

            .split-panel-v3__image {
              padding-top: ${square_media_ratio ? '100%' : 'calc((6 / 5) * 100%)'};
            }

            .split-panel-v3__video {
              padding-top: ${square_media_ratio ? '100%' : 'calc((6 / 5) * 100%)'};
            }

            .calendlyContainer {
              box-sizing: border-box;
              position: fixed;
              top: 50%;
              left: 50%;
              -webkit-transform: translateY(-50%) translateX(-50%);
              transform: translateY(-50%) translateX(-50%);
              width: 80%;
              min-width: 900px;
              height: 90%;
              max-height: 680px;
              z-index: 60;
            }

            @media (max-width: 768px) {
              .splitPanelV3Container {
                padding-top: ${mobile_padding_top};
                padding-bottom: ${mobile_padding_bottom};
              }
              .calendlyContainer {
                min-width: 100%;
              }
            }
          `}
        </style>
      </div>
    </section>
  );
}

export default SplitPanelV3
