import { aspectRatio } from '@lib/aspectRatio'
import { FC } from 'react'

interface props {
  html: string
  width: number
  height: number
}

const EmbeddedVideo: FC<props> = ({ html, width, height }) => {
  return (
    <>
      <div className="video-container" dangerouslySetInnerHTML={{ __html: `${html}` }}></div>
      <style jsx global>
        {`
          .video-container {
            position: relative;
          }

          .video-container::before {
            ${aspectRatio(width, height)}
          }

          .video-container iframe {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
          }
        `}
      </style>
    </>
  )
}

export default EmbeddedVideo
